import { defaultTheme } from "react-admin";

export const lightTheme = {
  palette: {
    primary: {
      main: "#5E4CCE",
      dark: "#261073",
      light: "#DBD2FF",
    },
    secondary: {
      main: "#F2EFFF",
    },
    info: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    background: {
      card: "#F2EFFF",
      paper: "#FFF",
      default: "#f2f2f2",
    },
    text: {
      primary: "#261073",
      secondary: "#80828A",
    },
  },
  typography: {
    fontFamily: "'Gilroy', sans-serif",
    button: {
      textTransform: "none",
      fontWeight: "700",
    },
    body2: {
      fontSize: "12px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #4f3cc9",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          border: "1px solid #e0e0e3",
          backgroundClip: "padding-box",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#808080",
          backgroundColor: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
      
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          borderRadius: 100,
          backgroundColor: "#26262E",
          color: "#FFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: "10px 17px",
        },
      },
    },
  },
};
