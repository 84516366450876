import React, { useState, useEffect } from "react";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useDataProvider } from "react-admin";
import moment from "moment";

const SetPaymentPlanForm = ({ userId }) => {
  const dataProvider = useDataProvider();

  // State for dropdown, expiration date, and selected pricing tier
  const [pricingTiers, setPricingTiers] = useState([]);
  const [selectedTier, setSelectedTier] = useState("");
  const [ expiresDate, setExpiresDate ] = useState( "" );
  const [appStoreTransactionId, setAppStoreTransactionId] = useState("");

  // Fetch pricing tiers using react-query
  const { data, isLoading: isFetching } = useQuery("getAllPricingTiers", () =>
    dataProvider.getAllPricingTiers()
  );

  // Populate pricing tiers when data is fetched
  useEffect(() => {
    console.log(`Tiers: ${JSON.stringify(data)}`);
    if ( data && Array.isArray( data.data ) ) {
      const plans = data.data.filter( tier => !tier._id.startsWith( 'wp' ) );
      plans.sort((a,b) => a.name < b.name ? -1 : 1);
      setPricingTiers(plans);
    }
  }, [data]);

  // Mutation for setting payment plan
  const { mutate, isLoading: isSaving } = useMutation(
    () =>
      dataProvider.setPaymentPlan({
        userId: userId,
        pricingTierId: selectedTier,
        expiresDate: moment( expiresDate ).utc().toISOString(),
        appStoreTransactionId: appStoreTransactionId !== "" ? appStoreTransactionId : null,
      }),
    {
      onSuccess: () => {
        alert("Payment plan updated successfully!");
      },
      onError: (error) => {
        alert(`Error updating payment plan: ${error.message}`);
      },
    }
  );

  const handleSave = () => {
    if (!selectedTier || !expiresDate) {
      alert("Please select a pricing tier and expiration date.");
      return;
    }
    mutate();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxWidth: "400px",
      }}
    >
      <h3>Set Payment Plan</h3>

      <Select
        value={selectedTier}
        onChange={(e) => setSelectedTier(e.target.value)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>
          Select Pricing Tier
        </MenuItem>
        {pricingTiers.map((tier) => (
          <MenuItem key={tier._id} value={tier._id}>
            {tier.name} ({tier._id})
          </MenuItem>
        ))}
      </Select>

      <TextField
        label="Expires Date"
        type="date"
        value={expiresDate}
        onChange={(e) => setExpiresDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />

      <TextField
      label="App Store Transaction ID"
        value={ appStoreTransactionId }
        onChange={ ( e ) => setAppStoreTransactionId( e.target.value ) }
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />



      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={isSaving || isFetching}
      >
        {isSaving ? "Saving..." : "Save"}
      </Button>
    </div>
  );
};

export default SetPaymentPlanForm;
