import axios from 'axios';
import { Button } from '@mui/material';
import { useState } from 'react';

import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  useRecordContext,
  Link,
  Edit,
  TextInput,
  required,
  TabbedForm,
  FormTab,
  SavedQueriesList,
  FilterList,
  FilterListItem,
  ReferenceManyField,
  Pagination,
  BooleanField,
  FunctionField,
  Filter
} from "react-admin";
import { useParams } from 'react-router-dom';
import { Card, CardContent } from "@mui/material";
import CustomDateField, { deletedRowStyle, UserLinkField } from "./users";
import { Avatar, Typography } from "@mui/material";

import MailIcon from "@mui/icons-material/MailOutline";
import CategoryIcon from "@mui/icons-material/LocalOffer";
import { ChannelLinkField } from "./channels";

import { useDataProvider } from "react-admin";
import { useEffect } from 'react';
import { SimpleShowLayout } from 'react-admin';
import SetWorkspacePaymentPlanForm from "../components/SetWorkspacePaymentPlanForm";

const AvatarField = ({ size = "25", sx }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Avatar
      src={`${record.image_url}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      variant="rounded"
      sx={sx}

    />
  );
};

export const FullNameField = (props) => {
  const { size } = props;
  const record = useRecordContext();
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      <AvatarField
        record={record}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {record.name}
    </Typography>
  ) : null;
};
export const WorkspaceLinkField = (props) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/workspaces/${record.id}`}>
      <FullNameField />
    </Link>
  );
};

const workspaceFilters = [
  <TextInput label="Workspace ID" source="id" alwaysOn />,
  <TextInput label="Workspace Name" source="name" alwaysOn />
];

export const WorkspaceFilterSidebar = () => (
  <Card
    sx={{ order: -1, flex: "0 0 15em", mr: 2, mt: 8, alignSelf: "flex-start" }}
  >
    <CardContent sx={{ pt: 1 }}>
      <SavedQueriesList />
      <FilterList label="Type" icon={<MailIcon />}>
        <FilterListItem label="standard" value={{ type: "standard" }} />
        <FilterListItem label="webcontact" value={{ type: "webcontact" }} />
        <FilterListItem label="personallink" value={{ type: "personallink" }} />
      </FilterList>
      <FilterList label="Plan" icon={<CategoryIcon />}>
        <FilterListItem
          label="Business-premium"
          value={{ plan_type: "business-premium" }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export const WorkspaceList = (props) => (
  <List {...props} aside={<WorkspaceFilterSidebar />} filters={workspaceFilters}>
    <Datagrid rowClick="edit" rowStyle={deletedRowStyle}>
      <TextField source="id" />
      <WorkspaceLinkField source="name" label="Workspace name" />
      <TextField source="description" />
      <ReferenceField source="owner_id" reference="users">
        <UserLinkField />
      </ReferenceField>
      <TextField source="type" />
      <CustomDateField source="last_updated_on" label="Updated" />
      <CustomDateField source="created_at"  />
      <CustomDateField source="deleted_on"  />
    </Datagrid>
  </List>
);

const WorkspaceTitle = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      {record.name} ({record.id})
    </span>
  ) : null;
};

const PaymentSettings = (props) =>  {
  
  const record = useRecordContext(); // Assuming this provides your record data
  const dataProvider = useDataProvider();
  
  const [paymentPlans, setPaymentPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!record?.id) return;

    const fetchPaymentPlans = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await dataProvider.getPaymentPlanForWorkspace({ id: record.id });
        setPaymentPlans(response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching payment plans.");
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentPlans();
  }, [dataProvider, record?.id]);

  return (
    <SimpleShowLayout>
      <SetWorkspacePaymentPlanForm workspaceId={record.id} />
      
          
          {loading && <p>Loading payment plans...</p>}
          {error && <p>Error: {error}</p>}
          {!loading && !error && paymentPlans && (
            <>
          <h4>Payment Plan Summary</h4>
          <a href={`https://dashboard.stripe.com/customers/${record.stripe_customer_id}`} target="_blank">Go To Stripe</a>
              <ul>
                {Object.entries(paymentPlans.payment_plan_summary).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong>{" "}
                    {Array.isArray(value)
                      ? value.join(", ")
                      : value !== null
                      ? value.toString()
                      : "-"}
                  </li>
                ))}
              </ul>
              <h4>Managed Plans</h4>
              {paymentPlans.managed_plans.length > 0 ? (
                <ul>
                  {paymentPlans.managed_plans.map((plan, index) => (
                    <li key={index}>
                      {JSON.stringify(plan, null, 2)} {/* Display plan details */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No managed plans available.</p>
              )}
            </>
          )}
       
    </SimpleShowLayout>
  );
};


const req = [required()];

const UserStatusField = ({ record, workspaceRecord }) => {
  const referencedUserId = record._id; // Assuming user_id is the identifier
  const user = workspaceRecord?.users.find((user) => user._id === referencedUserId);
  const userStatus = user?.status;
  return userStatus || 'N/A';
};

const RoleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Role" source="role" alwaysOn />
  </Filter>
);

export const WorkspaceEdit = () => {

  const { id } = useParams(); // Access the 'id' parameter from the URL
  const [updateStatus, setUpdateStatus] = useState('default'); // Track the update status

  // Handle the update workspace updated at status
  const handleUpdate = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/admin/workspace/updatedAt/${id}`;
      const headers = {
        'pxtoken': localStorage.getItem("pxtoken"),
      };
      await axios.put(url, null, {headers});
      setUpdateStatus('updated'); // Update the status on success
    } catch (error) {
      console.error('Error updating workspace:', error);
      // Handle the error appropriately
    }
  };

  return (
    <Edit title={<WorkspaceTitle />}>
      <TabbedForm>
        <FormTab label="Info">
          <TextInput source="name" fullWidth validate={req} />
          <TextInput source="description" fullWidth validate={req} />
          {/* Update workspace updated_on value */}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpdate}
          >
            {updateStatus === 'updated' ? 'Updated!' : 'Refresh Updated At'}
          </Button>
        </FormTab>
        <FormTab label="Users">

          <ReferenceManyField
            reference="users"
            target="workspace_roles.workspace_id"
            pagination={<Pagination />}
            filters={<RoleFilter />}
          >
            <Datagrid
              sx={{
                width: "100%",
                "& .column-comment": {
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },

              }}
            >
              <UserLinkField />
              <FunctionField
                label="Role"
                sortable={true}
                render={(record) => {

                  const workspaceRole = record.workspace_roles.find((role) => {

                    return role.workspace_id === id
                  });
                  return workspaceRole ? workspaceRole.role : '';
                }}

              />


              <CustomDateField source="last_seen_on" />
              <CustomDateField source="created_at" />
              <CustomDateField source="deleted_on" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>


        <FormTab label="Conversations">
          <ReferenceManyField
            reference="channels"
            target="workspace_id"
            pagination={<Pagination />}
          >
            <Datagrid
              sx={{
                width: "100%",
                "& .column-comment": {
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <TextField source="id" />
              <ChannelLinkField source="name" />
              <TextField source="description" />
              <ReferenceField source="owner_id" reference="users">
                <UserLinkField />
              </ReferenceField>
              <FunctionField
                source="users"
                render={(record) => record.users.length}
              />
              <TextField source="visibility" />
              <CustomDateField source="deleted_on" />
              <CustomDateField source="sort_order" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>

        <FormTab label="Payment Settings">
          <PaymentSettings />
        </FormTab>


      </TabbedForm>
    </Edit>
  )
};
